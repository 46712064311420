/** Gets the Exchange resource ID and appends the exchange section if supported */
export function getExchangeResource(exchangeName, section, useLongJpName) {
  const exchangePrefix = 'exchange.' + exchangeName;

  if (
    section &&
    section !== '????' &&
    section.trim().length !== 0 &&
    (exchangeName === 'TSE' ||
      (exchangeName === 'FSE' && (section === '0122' || section === '0123')))
  ) {
    return useLongJpName === true && supportsJPLongName(exchangeName)
      ? `${exchangePrefix}.${section}.long`
      : `${exchangePrefix}.${section}`;
  }

  return exchangePrefix;
}

function supportsJPLongName(exchangeName) {
  return exchangeName === 'TSE' ? true : false;
}
