import { createSelector } from 'reselect';

const globalMessagesSelector = (state) => state.user.globalMessages || [];
const localMessagesSelector = (state) => state.user.localMessages || [];
const stateUserSelector = (state) => state.user;
const tokenValidSelector = (state) => state.user.tokenIsValid;
const isValidatingTokenSelector = (state) => state.user.isValidatingToken;
const stateChartSettingsSelector = (state) => state.user.settings.chart;

export const allMessagesSelector = createSelector(
  [globalMessagesSelector, localMessagesSelector],
  (globalMessages, localMessages) => {
    let all = [];
    all.push(...globalMessages);
    all.push(...localMessages);
    return all;
  }
);

export const userSelector = createSelector([stateUserSelector], (user) => user);

export const chartSettingsSelector = createSelector(
  [stateChartSettingsSelector],
  (chartSettings) => chartSettings
);

// Selector to get the token validity. This helps the application to know if the
// token validity has changed (after token is validated)
export const userTokenValidSelector = createSelector(
  [tokenValidSelector],
  (tokenIsValid) => tokenIsValid
);

// Selector to get status if token is being validated
export const userIsValidatingTokenSelector = createSelector(
  [isValidatingTokenSelector],
  (isValidatingToken) => isValidatingToken
);
