import _ from 'lodash';
import types from './_actionTypes';
import { getBoardById } from '../utils';
import { addLocalMessage, dismissLocalMessage } from './user';
import Logger from '../utils/logger';
import { handleErrors, redirectToLoginOrThrowError } from '../utils/common';

function getRedirectUrl() {
  return `${process.env.REACT_APP_MONEX_REDIRECT_URL}`;
}

function getMarketboardsUrl(userId) {
  // prettier-ignore
  return `https://${process.env.REACT_APP_INSIGHT_API_DOMAIN}/user/${userId}/boards?format=ts`;
}

function createNewUserUrl(userId) {
  // prettier-ignore
  return `https://${process.env.REACT_APP_INSIGHT_API_DOMAIN}/user/${userId}/create`;
}

// Endpoint for Galaxy data
function getGalaxyUrl(userId, rz, dm) {
  // prettier-ignore
  return `https://${process.env.REACT_APP_INSIGHT_API_DOMAIN}/user/${userId}/positions?rz=${rz}&dm=${dm}&detailed=true`;
}

function receiveMarketboards(response) {
  let boards = [];
  let totalBoards = 0;
  let marketboardLastUpdate = undefined;

  if (response.data && response.data.Marketboards) {
    // Criteria when we should use Default Symbols list:
    // - marketboardLastUpdate is 0
    // - AND User has only 1 board with Basin default 8698 symbol
    if (
      response.data.marketboardLastUpdate === 0 &&
      response.data.Marketboards.length === 1
    ) {
      const boardSymbols = response.data.Marketboards[0].Symbols || [];
      if (
        boardSymbols.length === 1 &&
        boardSymbols[0].Symbol === 'JP:8698-TS'
      ) {
        return receiveMarketboardsAsNewUser(
          response.data.marketboardLastUpdate
        );
      }
    }

    boards = response.data.Marketboards;
    totalBoards = response.data.total;
    marketboardLastUpdate = response.data.marketboardLastUpdate;
  }

  return {
    type: types.RECEIVE_MARKETBOARDS,
    marketboards: boards,
    total: totalBoards,
    marketboardLastUpdate: marketboardLastUpdate
  };
}

function receiveMarketboardsAsNewUser(marketboardLastUpdate) {
  return {
    type: types.RECEIVE_MARKETBOARDS,
    marketboards: [
      {
        Id: '1',
        Name: '登録1',
        Symbols: [
          {
            Position: 0,
            Symbol: 'JP:$MNK'
          },
          {
            Position: 1,
            Symbol: 'JP:$0000-TS'
          },
          {
            Position: 2,
            Symbol: '@NIY'
          },
          {
            Position: 3,
            Symbol: '$DJI'
          },
          {
            Position: 4,
            Symbol: '$COMPX'
          },
          {
            Position: 5,
            Symbol: '@YM'
          },
          {
            Position: 6,
            Symbol: '@ES'
          },
          {
            Position: 7,
            Symbol: '$INX'
          },
          {
            Position: 8,
            Symbol: 'USDJPY'
          },
          {
            Position: 9,
            Symbol: 'BMUS.10Y.YIELD'
          },
          {
            Position: 10,
            Symbol: '$BRTI'
          }
        ]
      }
    ],
    total: 1,
    marketboardLastUpdate: marketboardLastUpdate
  };
}

export function filterMarketboards(search) {
  return {
    type: types.FILTER_MARKETBOARDS,
    filter: search
  };
}

export function errorFetchingMarketboard(error) {
  return {
    type: types.ERROR_FETCHING_MARKETBOARD,
    error: error,
    errorCode: 'E0003'
  };
}

export function errorPostingMarketboard(error) {
  return {
    type: types.ERROR_POSTING_MARKETBOARD,
    error: error,
    errorCode: 'E0005'
  };
}

export function setSelected(id) {
  return {
    type: types.SET_SELECTED,
    selected: id
  };
}

export function setSelectedTab(index) {
  return {
    type: types.SET_SELECTED_TAB,
    selectedTab: index
  };
}

export function selectView(selected) {
  return {
    type: types.SELECT_MARKETBOARD_VIEW,
    selectedView: selected
  };
}

export function toggleEdit() {
  return {
    type: types.TOGGLE_EDIT
  };
}

export function deleteSymbol(marketboardId, symbolPosition) {
  return {
    type: types.DELETE_SYMBOL,
    symbolPosition: symbolPosition,
    marketboardId: marketboardId
  };
}

export function replaceMarketboardSymbols(marketboardId, symbols) {
  return {
    type: types.REPLACE_MARKETBOARD_SYMBOLS,
    marketboardId: marketboardId,
    symbols: symbols
  };
}

function updateMarketboardName(marketboardId, name) {
  return {
    type: types.UPDATE_MARKETBOARD_NAME,
    marketboardId: marketboardId,
    name: name
  };
}

export function updateMarketboardSymbol(marketboardId, symbolPosition, symbol) {
  return {
    type: types.UPDATE_MARKETBOARD_SYMBOL,
    marketboardId: marketboardId,
    symbolPosition: _.parseInt(symbolPosition),
    symbol: symbol
  };
}

export function moveMarketboard(fromIndex, toIndex) {
  return {
    type: types.MOVE_MARKETBOARD,
    fromIndex,
    toIndex
  };
}

function shouldUpdateMarketboardName(dispatch, state, marketboardId, name) {
  const marketboard = getBoardById(state.marketboard.user, marketboardId);
  let shouldUpdate = false;
  if (!marketboard || !marketboard.Name) {
    shouldUpdate = false;
  } else if (marketboard.Name !== name) {
    shouldUpdate = true;
  }
  return shouldUpdate;
}

export function updateMarketboardNameIfNeeded(marketboardId, name) {
  return (dispatch, getState) => {
    if (
      shouldUpdateMarketboardName(dispatch, getState(), marketboardId, name)
    ) {
      return dispatch(updateMarketboardName(marketboardId, name));
    }
  };
}

export function getGalaxyMarketboards() {
  return (dispatch, getState) => {
    let user = getState().user;
    if (
      user.id &&
      user.tokenIsValid === true &&
      user.token &&
      user.dm &&
      user.rz &&
      domainIsValid(user.dm)
    ) {
      let req = {
        method: 'get',
        headers: { authorization: user.token }
      };
      return fetch(getGalaxyUrl(user.id, user.rz, user.dm), req)
        .then(handleErrors)
        .then((response) => response.json())
        .then((response) => {
          if (response.ok && response.data) {
            dispatch(receivePositions(response));
          }
        })
        .catch((err) => {
          Logger.error({
            errorCode: 'E0004',
            error: err,
            message: 'Error fetching position marketboard data'
          });
          return err;
        });
    }
  };

  function domainIsValid(domain) {
    let validDomains = ['mxp1', 'mxp2', 'mxp3', 'www', 'trade', 'dev'];
    return validDomains.indexOf(domain) > -1;
  }

  function receivePositions(response) {
    if (response.data) {
      let cashBoards = Array.isArray(response.data.positions.cash)
        ? response.data.positions.cash
        : [];
      let marginBoards = Array.isArray(response.data.positions.margin)
        ? response.data.positions.margin
        : [];

      return {
        type: types.UPDATE_POSITION_BOARDS,
        cashBoards: cashBoards,
        marginBoards: marginBoards
      };
    }
  }
}

function handleGetMarketboardsError(response, dispatch, getState) {
  if (
    response.errorCode === 'USER_NOT_FOUND' ||
    (response.ok && response.data.total === 0)
  ) {
    dispatch(receiveMarketboardsAsNewUser());
  } else if (
    (!getState().user.id && response.errorCode === 'NOT_FOUND') ||
    response.errorCode === 'TOKEN_EXPIRED' ||
    response.errorCode === 'TOKEN_INVALID' ||
    response.errorCode === 'TOKEN_MISSING'
  ) {
    window.location.href = getRedirectUrl();
  } else {
    throw Error('Error fetching marketboards');
  }
}

function isResponseOK(response) {
  return response.ok && response.data.total > 0;
}

function fetchMarketboards(user) {
  let req = {
    method: 'get',
    headers: { authorization: user.token }
  };

  return fetch(getMarketboardsUrl(user.id), req).then((response) =>
    response.json()
  );
}

function createNewUser(user) {
  let data = {
    data: {
      user: {
        id: user.id
      }
    }
  };

  let putReq = {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      authorization: user.token
    }
  };

  return fetch(createNewUserUrl(user.id), putReq).then((response) =>
    response.json()
  );
}

function handleFetchMarketboards(
  response,
  user,
  dispatch,
  getState,
  createUser,
  retries
) {
  if (isResponseOK(response)) {
    dispatch(receiveMarketboards(response));
    return;
  }

  if (response.errorCode !== 'USER_NOT_FOUND' || retries === 4) {
    handleGetMarketboardsError(response, dispatch, getState);
    return;
  }

  const promise = () => {
    return createUser ? createNewUser(user) : Promise.resolve();
  };

  return promise()
    .then(() => {
      return new Promise((resolve) => setTimeout(resolve, retries * 1000));
    })
    .then(() => {
      return fetchMarketboards(user);
    })
    .then((response) => {
      return handleFetchMarketboards(
        response,
        user,
        dispatch,
        getState,
        false,
        retries + 1
      );
    });
}

export function getMarketboards() {
  return (dispatch, getState) => {
    let user = getState().user;
    if (!user.id || user.tokenIsValid !== true || !user.token) {
      Logger.error({
        errorCode: 'E0010',
        message: 'Error fetching marketboards',
        error: 'User invalid for request',
        user: user
      });
      // There will be situations user object is not yet initialized with 
      // user info and credentials (id, token, etc) at the time we fetch
      // marketboards. When this happens, we will just return and wait for 
      // the user object to be properly initialized (id, token, etc), or 
      // dispatch an error it is still not initialized after several retries.
      if (isNaN(user.tokenRetry)) {
        user.tokenRetry = 0;
      }
      user.tokenRetry += 1;
      if (user.tokenRetry > 3) {
        dispatch(errorFetchingMarketboard(user));
        dispatch(receiveMarketboardsAsNewUser());
        dispatch(addLocalMessage('message.marketboards.loading.error'));
        return Promise.resolve();
      }
      return Promise.resolve();
    }

    return fetchMarketboards(user)
      .then((response) => {
        return handleFetchMarketboards(
          response,
          user,
          dispatch,
          getState,
          true,
          1
        );
      })
      .catch((err) => {
        dispatch(errorFetchingMarketboard(err));
        dispatch(receiveMarketboardsAsNewUser());
        dispatch(addLocalMessage('message.marketboards.loading.error'));
      });
  };
}

function responseHasMarketboards(response) {
  return (
    response.data &&
    response.data.Marketboards &&
    response.data.Marketboards.length > 0
  );
}

function marketboardPostIsForbidden(state) {
  return !!state.marketboard && state.marketboard.isSaveForbidden === true;
}

export function postMarketboards() {
  return (dispatch, getState) => {
    let state = getState();
    if (marketboardPostIsForbidden(state)) {
      return Promise.resolve();
    }

    let user = getState().user;
    if (user.id && user.tokenIsValid === true && user.token) {
      let url = getMarketboardsUrl(user.id);
      return Promise.resolve(state)
        .then((state) => getBasinFormattedData(state))
        .then((data) => {
          return fetch(url, data);
        })
        .then((response) => response.json())
        .then((response) => {
          if (response.ok) {
            dispatch(dismissLocalMessage('message.marketboards.saving.error'));
            // In order to update the `marketboardLastUpdate` property, we need
            // to update the marketboards with the response of the POST request.
            if (responseHasMarketboards(response)) {
              // Since the POST response doesn't include the required `total`
              // property, we add it manually here.
              response.data.total = response.data.Marketboards.length;
              dispatch(receiveMarketboards(response));
              return response;
            }
          } else if (response.status === 401) {
            dispatch(addLocalMessage('message.marketboards.saving.expired'));
          } else if (response.errorCode === 'CONFLICT') {
            dispatch(addLocalMessage('message.marketboards.saving.conflict'));
          } else {
            return redirectToLoginOrThrowError(
              response,
              'Error when attempting to update marketboards'
            );
          }
          return response;
        })
        .catch((err) => {
          dispatch(errorPostingMarketboard(err));
          dispatch(addLocalMessage('message.marketboards.saving.error'));
        });
    } else {
      Logger.error({
        errorCode: 'E0009',
        message: 'user invalid in marketboard post: ' + JSON.stringify(user)
      });
      dispatch(
        errorPostingMarketboard(
          'user invalid in marketboard post' + JSON.stringify(user)
        )
      );
      dispatch(addLocalMessage('message.marketboards.saving.error'));
      return Promise.resolve();
    }
  };
}

// Currently we will only send the user marketboards, but we could change this to send all
function getBasinFormattedData(state) {
  if (!state.marketboard || !state.marketboard.user) {
    throw Error('no state or no marketboard to post');
  }

  let data = {
    data: {
      Marketboards: state.marketboard.user,
      marketboardLastUpdate: state.marketboard.marketboardLastUpdate
    }
  };

  let postData = {
    method: 'post',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      authorization: state.user.token
    },
    body: JSON.stringify(data)
  };
  return postData;
}

export function addMarketboard(marketboard) {
  return {
    type: types.MARKETBOARD_ADD,
    marketboard: marketboard
  };
}

export function deleteMarketboard(id) {
  return {
    type: types.MARKETBOARD_DELETE,
    marketboardId: id
  };
}
