import React from 'react';
import * as _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import { getExchangeResourceId } from '../../utils/symbol';

class Nikkei225Flag extends React.PureComponent {
  render() {
    let renderValue = null;

    if (this.props.indexes && this.props.indexes.indexOf('Nikkei225') > -1) {
      renderValue = (
        <div className={'symbol-details-nikkei-flag'}>
          <FormattedMessage id={'symbol.details.nikkei.flag'} />
        </div>
      );
    }

    return renderValue;
  }
}

class SymbolStatusFlag extends React.PureComponent {
  render() {
    let renderValue = null;

    if (this.props.resourceKeys && this.props.resourceKeys.length > 0) {
      renderValue = (
        <div className={'symbol-details-status-flag'}>
          {this.props.resourceKeys.map((key) => {
            return <FormattedMessage id={`symbol.details.${key.toString()}`} />;
          })}
        </div>
      );
    }

    return renderValue;
  }
}

class PageSubtitle extends React.Component {
  localize(i18nKey) {
    return this.props.intl.formatMessage({
      id: i18nKey
    });
  }

  render() {
    if (!this.props.show) {
      return null;
    }

    return (
      <div className={'symbol-detail-page-subtitle'}>
        {this.props.showExchange &&
          this.localize(getExchangeResourceId(this.props.symbolData, true)) + ' '}
        {this.localize(
          'symbol.industry.' + _.get(this.props, 'symbolData.jpIndustry', 9999)
        )}
        <Nikkei225Flag indexes={_.get(this.props, 'symbolData.indexes', [])} />
        <SymbolStatusFlag
          resourceKeys={_.get(
            this.props,
            'symbolData.financialStatusResourceKeys',
            []
          )}
        />
      </div>
    );
  }
}

export default injectIntl(PageSubtitle);
