import _ from 'lodash';
import types from '../actions/_actionTypes';
import { faucet } from '../faucet';
import queryString from 'query-string';
import * as lsUtils from '../utils/localStorage';

const SETTINGS = 'SETTINGS';
const DISMISSED_MESSAGE_IDS = 'DISMISSED_MESSAGE_IDS';

function getInitialState() {
  const USER_ID_KEY = 'USER_ID_KEY';
  const TOKEN_KEY = 'TOKEN_KEY';
  const USER_LANGUAGE = 'USER_LANGUAGE';
  const USER_RZ = 'USER_RZ';
  const USER_RF = 'USER_RF';
  const USER_DM = 'USER_DM';
  const USER_SRC_KEY = 'USER_SRC_KEY';
  const USER_ENCRYPTED_ID = 'USER_ENCRYPTED_ID';
  const USER_IFIS_HASH = 'USER_IFIS_HASH';

  var qs = queryString.parse(window.location.search);
  var state = {
    isValidatingToken: true,
    tokenIsValid: false,
    id: qs.userId || window.localStorage.getItem(USER_ID_KEY) || '',
    rz: qs.rz || window.localStorage.getItem(USER_RZ) || '',
    rf: qs.rf || window.localStorage.getItem(USER_RF) || '',
    dm: qs.dm || window.localStorage.getItem(USER_DM) || '',
    encryptedId:
      qs.encryptedId ||
      window.localStorage.getItem(USER_ENCRYPTED_ID) ||
      'mxp0',
    internal: false,
    attrSrcKey:
      qs.attrSrcKey || window.localStorage.getItem(USER_SRC_KEY) || '',
    token: qs.token || window.localStorage.getItem(TOKEN_KEY) || '',
    locale: parseLocale(
      qs.language || window.localStorage.getItem(USER_LANGUAGE) || 'ja-JP'
    ),
    ifisHash: window.localStorage.getItem(USER_IFIS_HASH) || '',
    globalMessages: [],
    localMessages: [],
    settings: parseSettings(),
    dismissedMessageIds: parseDismissedMessages()
  };
  window.localStorage.setItem(USER_ID_KEY, state.id);
  window.localStorage.setItem(TOKEN_KEY, state.token);
  window.localStorage.setItem(USER_LANGUAGE, state.locale);
  window.localStorage.setItem(USER_RZ, state.rz);
  window.localStorage.setItem(USER_DM, state.dm);
  window.localStorage.setItem(USER_SRC_KEY, state.attrSrcKey);
  window.localStorage.setItem(USER_IFIS_HASH, state.ifisHash);
  return state;
}

function parseLocale(locale) {
  switch (locale) {
    case 'en-US':
    case 'en':
      locale = 'en-US';
      break;
    case 'ja-JP':
    case 'ja':
    default:
      locale = 'ja-JP';
      break;
  }
  return locale;
}

function parseSettings() {
  let settings = window.localStorage.getItem(SETTINGS);
  let result;

  // First get the chart settings
  if (settings) {
    result = JSON.parse(settings);
  } else {
    result = {
      chart: {
        chartStyle: 'candlestick',
        displayTooltip: true,
        period: {
          type: 'month',
          count: 3
        },
        indicators: []
      }
    }
  }

  // Then get the remaining settings
  result.includeJpeq = lsUtils.getIncludeJpeq();
  result.includeUseq = lsUtils.getIncludeUseq();
  result.includeSupplementals = lsUtils.getIncludeSupplementals();
  result.includeIndustry = lsUtils.getIncludeIndustry();

  return result;
}

function saveSettings(settings) {
  // Since the `includeJpeq` properties were artificially added,
  // we will remove them from the settings to save. For now,
  // we only need to save the chart property, for that reason,
  // we are copying it here.
  let settingsToSave = {
    chart: Object.assign({}, settings.chart)
  };

  settingsToSave = JSON.stringify(settingsToSave);
  window.localStorage.setItem(SETTINGS, settingsToSave);
}

function parseDismissedMessages() {
  let messageIds = window.localStorage.getItem(DISMISSED_MESSAGE_IDS);
  if (messageIds) {
    return JSON.parse(messageIds);
  }

  return [];
}

function saveDismissedMessages(messageIds) {
  messageIds = JSON.stringify(messageIds);
  window.localStorage.setItem(DISMISSED_MESSAGE_IDS, messageIds);
}

const user = (state = getInitialState(), action) => {
  var newState;
  switch (action.type) {
    case types.SET_TOKEN:
      newState = Object.assign({}, state);
      newState.token = action.token || state.token;
      faucet.setToken(newState.token);
      return newState;
    case types.SET_DM_PARAMETER:
      newState = Object.assign({}, state);
      newState.dm = action.dm || state.dm;
      return newState;
    case types.SET_RZ_PARAMETER:
      newState = Object.assign({}, state);
      newState.rz = action.rz || state.rz;
      return newState;
    case types.SET_USER_SRC_KEY:
      newState = Object.assign({}, state);
      newState.attrSrcKey = action.attrSrcKey || state.attrSrcKey;
      return newState;
    case types.SET_USER:
      newState = Object.assign({}, state);
      newState.id = action.id || state.id;
      return newState;
    case types.USER_VALIDATE_TOKEN:
      newState = Object.assign({}, state);
      newState.isValidatingToken = false;
      newState.tokenIsValid = action.isValid;
      newState.attrSrcKey = action.attrSrcKey || state.attrSrcKey || '';
      newState.dm = action.dm || state.dm || '';
      newState.encryptedId = action.encryptedId || state.encryptedId || '';
      newState.internal = action.internal || state.internal || false;
      newState.rf = action.rf || state.rf || '';
      newState.rz = action.rz || state.rz || '';
      newState.hash = action.hash || state.hash || '';
      newState.ifisHash = action.ifisHash || state.ifisHash || '';
      newState.isMarginEnabled = newState.rf === 'y';
      return newState;
    case types.USER_ERROR_VALIDATING_TOKEN:
      newState = Object.assign({}, state);
      newState.isValidatingToken = false;
      newState.tokenIsValid = false;
      return newState;
    case types.RECEIVE_GLOBAL_MESSAGES:
      newState = Object.assign({}, state);
      newState.globalMessages = action.globalMessages;
      return newState;
    case types.ADD_LOCAL_MESSAGE:
      newState = Object.assign({}, state);
      newState.localMessages = [];
      newState.localMessages.push(...state.localMessages);
      // Avoid pushing messages with the same key so consecutive failures don't
      // asplode the global message area with lots o' messages.
      function hasSameKey(msg) {
        return (
          typeof msg === 'object' &&
          'Key' in msg &&
          msg.Key === action.message.Key
        );
      }
      if (
        typeof action.message !== 'object' ||
        !('Key' in action.message) ||
        !newState.localMessages.some(hasSameKey)
      ) {
        newState.localMessages.push(action.message);
      }
      return newState;
    case types.DISMISS_LOCAL_MESSAGE:
      newState = Object.assign({}, state);
      newState.localMessages = state.localMessages.filter(
        (msg) => msg.Key !== action.Key
      );
      return newState;
    case types.DISMISS_GLOBAL_MESSAGE:
      newState = Object.assign({}, state);
      newState.dismissedMessageIds.push(action.messageId);
      saveDismissedMessages(newState.dismissedMessageIds);
      return newState;
    case types.SET_CHART_STYLE:
      newState = Object.assign({}, state);
      newState.settings.chart.chartStyle = action.value;
      saveSettings(newState.settings);
      return newState;
    case types.SET_CHART_MODE:
      newState = Object.assign({}, state);
      newState.settings.chart.chartMode = action.value;
      saveSettings(newState.settings);
      return newState;
    case types.SET_DISPLAY_TOOLTIP:
      newState = Object.assign({}, state);
      newState.settings.chart.displayTooltip = action.value;
      saveSettings(newState.settings);
      return newState;
    case types.SET_CHART_PERIOD:
      newState = Object.assign({}, state);
      newState.settings.chart.period = action.value;
      saveSettings(newState.settings);
      return newState;
    case types.SET_INDICATOR_PARAMETERS:
      newState = Object.assign({}, state);
      let idc = _.find(
        newState.settings.chart.indicators,
        (indicator) => indicator.type === action.indicatorType
      );
      idc.params = action.value;
      saveSettings(newState.settings);
      return newState;
    case types.SET_INDICATORS:
      newState = Object.assign({}, state);
      newState.settings.chart.indicators = action.value;
      saveSettings(newState.settings);
      return newState;
    case types.SET_DISABLED_INDICATORS:
      newState = Object.assign({}, state);
      newState.settings.chart.disabledIndicators = action.value;
      saveSettings(newState.settings);
      return newState;
    case types.SET_COMPARISON_SYMBOLS:
      newState = Object.assign({}, state);
      newState.settings.chart.comparisonSymbols = action.value;
      saveSettings(newState.settings);
      return newState;
    case types.SET_INCLUDE_JPEQ:
      newState = Object.assign({}, state);
      newState.settings.includeJpeq = action.value;
      lsUtils.setIncludeJpeq(action.value);
      return newState;
    case types.SET_INCLUDE_USEQ:
      newState = Object.assign({}, state);
      newState.settings.includeUseq = action.value;
      lsUtils.setIncludeUseq(action.value);
      return newState;
    case types.SET_INCLUDE_SUPPLEMENTALS:
      newState = Object.assign({}, state);
      newState.settings.includeSupplementals = action.value;
      lsUtils.setIncludeSupplementals(action.value);
      return newState;
    case types.SET_INCLUDE_INDUSTRY:
      newState = Object.assign({}, state);
      newState.settings.includeIndustry = action.value;
      lsUtils.setIncludeIndustry(action.value);
      return newState;
    default:
      return state;
  }
};

export default user;
