import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import ErrorPage from '../views/errors/ErrorPage';
import Logger from '../utils/logger';
import { FormattedMessage, useIntl } from 'react-intl';
import useActions from '../utils/hooks/useActions';
import { validateToken as validateTokenAction } from '../actions/user';
import { getMarketboards as getMarketboardsAction } from '../actions/marketboards';
import { initWebSocket as initWebSocketAction } from '../actions/stream';
import { useSelector } from 'react-redux';
import {
  userIsValidatingTokenSelector,
  userTokenValidSelector
} from '../selectors/userSelectors';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const intl = useIntl();
  const user = useSelector((state) => state.user);
  const tokenValid = useSelector(userTokenValidSelector);
  const isValidatingToken = useSelector(userIsValidatingTokenSelector);
  const authed = user.tokenIsValid && user.token !== '';
  const [validateToken] = useActions([validateTokenAction], []);
  const [getMarketboards] = useActions([getMarketboardsAction], []);
  const [initWebSocket] = useActions([initWebSocketAction], []);

  if (!isValidatingToken && !authed) {
    Logger.error({
      errorCode: 'E0009',
      message: 'Current user is not Authorized',
      component: Component,
      info: rest
    });
  }

  useEffect(() => {
    // execute token validation on mount
    validateToken();
  }, [validateToken]);

  useEffect(() => {
    // fetch marketboards and websocket initialization
    // after token is validated
    if (!tokenValid) {
      return;
    }
    getMarketboards();
    initWebSocket(user.token);
  }, [tokenValid, getMarketboards, initWebSocket, user.token]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (props.match && props.match.params) {
          Object.keys(props.match.params).forEach((key) => {
            props.match.params[key] = decodeURIComponent(
              props.match.params[key]
            );
          });
        }

        if (isValidatingToken) {
          return (
            <div className="no-content loading">
              <FormattedMessage id="message.status.loading" />
            </div>
          );
        }

        if (authed === true) {
          return <Component {...props} />;
        } else {
          return (
            <ErrorPage
              {...props}
              errorTitle={intl.formatMessage({
                id: 'message.status.error'
              })}
              errorMessage={intl.formatMessage({
                id: 'message.invalid.token'
              })}
            />
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;
